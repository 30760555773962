import styled from 'styled-components';
import { faBolt, faChargingStation, faHouseDamage, faSolarPanel } from '@fortawesome/free-solid-svg-icons';
import { Text, Banner } from 'ui';
import bgImg from 'media/bg_1600.jpg';
import { CallToAction } from 'components';

function AboutView() {
  return (
    <>
      <Banner backgroundImage={bgImg} />
      <div className="container py-5">
        <div className="col-12 col-md-8 mx-auto">
          <Text bold fontSize={36} className="mb-2">
            Våra tjänster
          </Text>
          <Text className="mb-3">Ljungskile Teknik AB är ett elinstallationsbolag grundat 2013.</Text>
          <Text className="mb-3">
            Efter att tidigare varit anställda hos GP Elektriska Ljungskile under flera års tid valde vi att ta över
            verksamheten efter Gunde Persson. Företaget drivs av bröderna Robin och Andreas Börjesson.
          </Text>
          <Text className="mb-3">Vi utför alla typer av elinstallationer åt så väl företag som privatpersoner.</Text>
        </div>
      </div>
      <ServicesContainer>
        <div className="container pt-5">
          <div className="col-12 col-md-8 mx-auto">
            <div className="row">
              <div className="col-12 col-md-6">
                <CallToAction title="Elektriker" icon={faBolt} />
              </div>
              <div className="col-12 col-md-6">
                <CallToAction title="Laddstolpar & laddstationer" icon={faChargingStation} />
              </div>
              <div className="col-12 col-md-6">
                <CallToAction title="Smarta hem" icon={faHouseDamage} />
              </div>
              <div className="col-12 col-md-6">
                <CallToAction title="Solceller" icon={faSolarPanel} />
              </div>
            </div>
          </div>
        </div>
      </ServicesContainer>
    </>
  );
}

const ServicesContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fill};
`;

export default AboutView;
