import { useProductsQuery } from 'generated/graphql';
import styled from 'styled-components';
import { Text, Banner } from 'ui';
import Skeleton from 'react-loading-skeleton';
import loadingImg from 'media/dummy-image.jpg';
import bannerImg from 'media/skylift.jpg';

const ProductView = () => {
  const { data, loading, error } = useProductsQuery();

  if (error) return <Text>Ett fel inträffade. Försök igen.</Text>;

  return (
    <>
      <Banner backgroundImage={bannerImg} />
      <ProductContainer>
        <div className="container mb-5">
          <Text fontSize={36} bold className="mb-4">
            Lokaler & fastigheter
          </Text>
          {loading && (
            <ProductCard className="row">
              <div className="col-12 col-md-6 pr-md-0">
                <ProductImage backgroundImage={loadingImg} />
              </div>
              <div className="col-12 col-md-6 pl-md-0">
                <Details>
                  <Skeleton height={40} width={180} style={{ marginBottom: 15 }} />
                  <Skeleton count={8} />
                  <Skeleton count={1} width={300} style={{ marginTop: 50 }} />
                </Details>
              </div>
            </ProductCard>
          )}
          {!loading && !data?.products?.edges?.length && (
            <EmptyResult>
              <Text large bold light>
                Det finns i dagsläget inga fastigheter tillgängliga. För mer info, kontakta oss på{' '}
                <a href="mailto: info@ljungskileteknik.se">info@ljungskileteknik.se</a>.
              </Text>
            </EmptyResult>
          )}
          {data?.products?.edges?.map((i) => (
            <ProductCard key={i?.node?.id} className="mb-5">
              <div className="row">
                <div className="col-12 col-md-6 pr-md-0">
                  {i?.node?.image?.mediaItemUrl && <ProductImage backgroundImage={i.node.image.mediaItemUrl} />}
                </div>
                <div className="col-12 col-md-6 pl-md-0">
                  <Details>
                    <Text fontSize={28} bold style={{ marginBottom: 15 }}>
                      {i?.node?.name}
                    </Text>
                    <Text large light>
                      {i?.node?.description?.replace(/<\/?[^>]+(>|$)/g, '')}
                    </Text>
                    <DetailsFooter>
                      {i?.node?.attributes?.nodes?.map((a) => (
                        <Attribute key={a?.name}>
                          <strong>{a?.name}</strong> {a?.options}
                        </Attribute>
                      ))}
                    </DetailsFooter>
                  </Details>
                </div>
              </div>
            </ProductCard>
          ))}
        </div>
      </ProductContainer>
    </>
  );
};

const ProductContainer = styled.div`
  background-color: ${(props) => props.theme.colors.fill};
  padding: 50px 0;
`;

const EmptyResult = styled.div`
  background-color: white;
  padding: 30px;
  margin-bottom: 50px;
`;

const ProductCard = styled.div`
  border-radius: 9px;
  overflow: hidden;
`;

const ProductImage = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-image: url('${(props) => props.backgroundImage}');
  background-size: cover;
  background-position: center;
`;

const Details = styled.div`
  background-color: white;
  padding: 30px;
  flex-grow: 1;
  position: relative;
`;

const DetailsFooter = styled.div`
  margin-top: 30px;
`;

const Attribute = styled.div`
  padding: 3px 8px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 5px;
  display: inline-block;
`;

export default ProductView;
