import styled from 'styled-components';
import { Category, usePostBySlugQuery } from 'generated/graphql';
import Skeleton from 'react-loading-skeleton';
import { Banner } from 'ui';
import { useParams } from 'react-router-dom';
import { CallToAction } from 'components';
import { faBolt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface IconMapObject {
  elektriker: IconDefinition;
}

function ContentView({ postSlug }: { postSlug?: string }) {
  const { slug } = useParams() as any;

  const getPostSlug = () => {
    return postSlug || slug;
  };

  const { data, loading, error } = usePostBySlugQuery({ variables: { slug: getPostSlug() } });

  if (error) return <div>{error.message}</div>;

  const getIcon = (categories: Category[] | null | undefined) => {
    if (!categories || !categories.length) return faBolt;

    const iconMap: IconMapObject = {
      elektriker: faBolt,
      // add more category mappings here
    };

    const firstCategory = categories[0];

    return iconMap[firstCategory.slug as keyof IconMapObject];
  };

  return (
    <>
      {data?.post?.featuredImage?.node?.mediaItemUrl && (
        <Banner backgroundImage={data.post.featuredImage.node.mediaItemUrl} />
      )}
      <div className="container py-5">
        <div className="d-flex justify-content-center">
          <CallToAction dark icon={getIcon(data?.post?.categories as Category[])} title={data?.post?.title || ''} />
        </div>
        <div className="col-12 col-md-8 mx-auto">
          <Content>
            {loading ? (
              <Skeleton count={6} height={30} style={{ marginBottom: 15 }} />
            ) : (
              data?.post?.content && <div dangerouslySetInnerHTML={{ __html: data.post.content }} />
            )}
          </Content>
        </div>
      </div>
    </>
  );
}

const Content = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  p {
    color: ${(props) => props.theme.colors.text};
    font-size: 18px;
  }
`;

export default ContentView;
