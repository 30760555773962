import styled from 'styled-components';

interface AsideProps {
  readonly bgfill?: boolean;
  readonly border?: boolean;
  readonly offsetTop?: number;
  readonly direction?: 'LEFT' | 'RIGHT';
  readonly width?: number;
}

export const Aside = styled.div<AsideProps>`
  position: fixed;
  top: ${(props) => (props.offsetTop ? props.offsetTop + 'px' : '0')};
  ${(props) =>
    props.direction === 'RIGHT'
      ? `
    right: 0;
  `
      : `
    left: 0
  `};
  bottom: 0;
  width: ${(props) => (props.width ? props.width + 'px' : '350px')};
  background-color: ${(props) => (props.bgfill ? props.theme.colors.fill : '#fff')};
  border: ${(props) => (props.border ? '1px solid #dddddd' : '0')};
  overflow-y: scroll;
`;
