import styled from 'styled-components';

interface ButtonProps {
  readonly primary?: boolean;
  readonly accent?: boolean;
  readonly height?: number;
  readonly backgroundColor?: string;
  readonly textColor?: string;
  readonly pill?: boolean;
}

export const Button = styled.button<ButtonProps>`
  font-size: 13px;
  text-transform: uppercase;
  padding: 0 35px;
  border-radius: 5px;
  border: 0;
  color: ${(props) => props.theme.colors.text};
  font-weight: 700;
  background-color: ${(props) => props.theme.colors.fill};
  transition: opacity 0.3s ease, background-color 0.3s ease;
  user-select: none;

  height: ${(props) => (props.height ? props.height + 'px' : '35px')};
  line-height: ${(props) => (props.height ? props.height + 'px' : '35px')};

  ${(props) =>
    props.pill &&
    `
     border-radius: ${props.height ? props.height / 2 : '100'}px;
 `};

  ${(props) =>
    props.primary &&
    `
     background-color: ${props.theme.colors.primary};
     color: #fff;
 `};

  ${(props) =>
    props.accent &&
    `
     background-color: ${props.theme.colors.secondary}; 
     color: #fff;
 `};

  ${(props) =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor} !important;
    color: #fff;
  `};

  ${(props) =>
    props.textColor &&
    `
   color: ${props.textColor} !important;

   > a {
     color: ${props.textColor} !important;
   }
  `};

  > a {
    color: #fff;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.fill};
    color: ${(props) => props.theme.colors.fill_dark};
  }

  &:hover {
    opacity: 0.9;
  }
`;
