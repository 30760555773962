import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Text } from 'ui';

interface ICallToAction {
  title: string;
  description?: string;
  icon: IconProp;
  dark?: boolean;
}

const CallToAction: React.FC<ICallToAction> = ({ title, description, icon, dark, ...rest }) => {
  return (
    <Container {...rest} dark={dark}>
      <FontAwesomeIcon icon={icon} size="2x" className="d-inline-block mr-2" />
      <Header bold className="d-inline-block">
        {title}
      </Header>
      {description && <Text>{description}</Text>}
    </Container>
  );
};

const Container = styled.div<{ dark?: boolean }>`
  margin-bottom: 30px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;

  ${({ dark }) =>
    dark &&
    css`
      background-color: #f7f7f7;
    `}
`;

const Header = styled(Text)`
  font-size: 18px;
  color: #2f2f2f;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export default CallToAction;
