import styled from 'styled-components';
import { usePageByIdQuery } from 'generated/graphql';
import Skeleton from 'react-loading-skeleton';
import { FullPageBanner, Text } from 'ui';
import { useParams } from 'react-router-dom';

function ContentView({ pageId }: { pageId?: string }) {
  const { id } = useParams() as any;

  const getPageId = () => {
    return pageId || id;
  };

  const { data, loading } = usePageByIdQuery({ variables: { id: getPageId() } });

  return (
    <>
      {data?.page?.featuredImage?.node?.mediaItemUrl && (
        <FullPageBanner bgImg={data.page.featuredImage.node.mediaItemUrl} />
      )}
      <div className="container py-5">
        <div className="col-12 col-md-8 mx-auto">
          <Text bold fontSize={36} className="mb-2">
            {data?.page?.title}
          </Text>
          <Content>
            {loading ? (
              <Skeleton count={12} height={30} style={{ marginBottom: 15 }} />
            ) : (
              data?.page?.content && <div dangerouslySetInnerHTML={{ __html: data.page.content }} />
            )}
          </Content>
        </div>
      </div>
    </>
  );
}

const Content = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  p {
    color: ${(props) => props.theme.colors.text};
    font-size: 18px;
  }
`;

export default ContentView;
