import { Text, FullPageBanner } from 'ui';
import bgImg from 'media/bg3_1200.jpg';

function AboutView() {
  return (
    <>
      <FullPageBanner bgImg={bgImg} />
      <div className="container py-5">
        <div className="col-12 col-md-8 mx-auto">
          <Text bold fontSize={36} className="mb-2">
            Om oss
          </Text>
          <Text className="mb-3">Ljungskile Teknik AB är ett elinstallationsbolag grundat 2013.</Text>
          <Text className="mb-3">
            Efter att tidigare varit anställda hos GP Elektriska Ljungskile under flera års tid valde vi att ta över
            verksamheten efter Gunde Persson. Företaget drivs av bröderna Robin och Andreas Börjesson.
          </Text>
          <Text className="mb-3">Vi utför alla typer av elinstallationer åt så väl företag som privatpersoner.</Text>
        </div>
      </div>
    </>
  );
}

export default AboutView;
