import styled from 'styled-components';
import { Flex } from 'ui';
import { Link } from 'react-router-dom';
import logoImg from 'media/lt_logo.svg';

const Navigation = () => {
  return (
    <NavigationWrapper center>
      <StyledNavigation>
        <Flex center style={{ height: 50 }}>
          <MenuItem>
            <Link to={'/'}>
              <Logo src={logoImg} alt="Ljungskile Teknik AB" />
            </Link>
          </MenuItem>
        </Flex>
      </StyledNavigation>
    </NavigationWrapper>
  );
};

const Logo = styled.img`
  width: 250px;

  @media (min-width: 768px) {
    width: 350px;
  }
`;

const NavigationWrapper = styled(Flex)`
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) 35%, transparent);

  @media (min-width: 768px) {
    height: 100px;
  }
`;

const StyledNavigation = styled.div`
  padding: 0 15px;
  color: white;
  width: 100%;
`;

const MenuItem = styled.div`
  margin: 0;
  padding: 10px 0;
  margin-right: 30px;
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default Navigation;
