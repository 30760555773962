import styled from 'styled-components';

interface CardProps {
  readonly bgfill?: boolean;
  readonly border?: boolean;
  readonly borderRadius?: boolean;
  readonly expand?: boolean;
}

export const Card = styled.div<CardProps>`
  padding: 40px 30px;
  margin-bottom: 15px;
  background-color: ${(props) => (props.bgfill ? props.theme.colors.fill : '#fff')};
  border: ${(props) => (props.border ? '1px solid #dddddd' : '0')};

  ${(props) =>
    props.borderRadius &&
    `
    border-radius: ${props.theme.borderRadius};
  `}

  ${(props) =>
    props.expand &&
    `
    width: 100%;
  `}
`;
