import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { slide as Menu } from 'react-burger-menu';

// Global styles
import 'styles/app.scss';

// Components
import { Navigation, Footer } from 'components';

// Views
import { HomeView, ProductView, ContactView, AboutView, ServicesView, VacancyView, PostView } from 'views';

// Theme
import { appTheme } from 'styles/theme';

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: window.innerWidth > 768 ? '35px' : '25px',
  },
  bmBurgerBars: {
    background: '#FFF',
  },
  bmBurgerBarsHover: {
    background: '#FFF',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: 'white',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
    textDecoration: 'none',
    outline: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        <Menu isOpen={menuOpen} right styles={styles} pageWrapId={'page-wrap'}>
          <a href="/" onClick={() => setMenuOpen(false)}>
            Hem
          </a>
          <a href="/om-oss" onClick={() => setMenuOpen(false)}>
            Om oss
          </a>
          <a href="/fastigheter" onClick={() => setMenuOpen(false)}>
            Fastigheter & lokaler
          </a>
          <a href="/tjanster" onClick={() => setMenuOpen(false)}>
            Tjänster
          </a>
          <a href="/lediga-tjanster" onClick={() => setMenuOpen(false)}>
            Lediga tjänster
          </a>
          <a href="/kontakt" onClick={() => setMenuOpen(false)}>
            Kontakt
          </a>
        </Menu>
        <div id="page-wrap">
          <Navigation />
          <Switch>
            <Route exact path="/">
              <HomeView />
            </Route>
            <Route exact path="/fastigheter">
              <ProductView />
            </Route>
            <Route exact path="/kontakt">
              <ContactView />
            </Route>
            <Route exact path="/om-oss">
              {/* <ContentView pageId="cG9zdDo2" /> */}
              <AboutView />
            </Route>
            <Route exact path="/tjanster">
              <ServicesView />
            </Route>
            <Route exact path="/lediga-tjanster">
              <VacancyView />
            </Route>
            <Route exact path="/lediga-tjanster/:slug">
              <PostView />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
