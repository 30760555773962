import styled from 'styled-components';
import { Text } from 'ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <Container>
      <div className="container text-center">
        <div className="row">
          <div className="col-12 mb-5 mb-md-0">
            <Text light large className="mb-3">
              <strong>Ljungskile Teknik AB</strong> <br />
              Backegårdsvägen 4 <br />
              45930 Ljungskile <br />
            </Text>
            <Text light large>
              Org.nr: 556923-4890 <br />
              Bankgiro: 157-8335
            </Text>
            <HugeText fontSize={36} bold light style={{ marginTop: 30, fontSize: 24 }}>
              <FontAwesomeIcon icon={faEnvelope} />{' '}
              <a href="mailto:info@ljungskileteknik.se">info@ljungskileteknik.se</a>
            </HugeText>
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.fill};
  color: white;
  padding: 50px 0;
  font-size: 18px;
`;

const HugeText = styled(Text)`
  a {
    color: ${(props) => props.theme.colors.text_light};
  }
`;

export default Footer;
