import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Text } from 'ui';
import { useEmployeesQuery } from 'generated/graphql';

import employeePlaceholderImg from 'media/employee-placeholder.png';

function ContactCard({
  name,
  title,
  phone,
  email,
  image,
}: {
  name: string;
  title?: string;
  phone: string;
  email: string;
  image?: string;
}) {
  return (
    <Card>
      <CardImage backgroundImage={image} />
      <CardBody>
        <Text light large className="mb-3 d-inline-block">
          <strong>{name}</strong>
          {title && <div className="mb-2">- {title} -</div>}
          <div>
            <FontAwesomeIcon icon={faPhone} /> {phone}
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${email}`}>{email}</a>
          </div>
        </Text>
      </CardBody>
    </Card>
  );
}

const Card = styled.div`
  border-radius: 9px;
  overflow: hidden;
`;

const CardImage = styled.div<{ backgroundImage?: string }>`
  height: 200px;
  background-color: ${(props) => props.theme.colors.fill};
  ${(props) =>
    props.backgroundImage &&
    `
    background-image: url('${props.backgroundImage}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `};
`;

const CardBody = styled.div`
  padding: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
`;

const ContactView = () => {
  const { data } = useEmployeesQuery();
  const [employees, setEmployees] = useState<
    { name: string; phone: string; email: string; order: number }[] | undefined
  >(undefined);

  useEffect(() => {
    if (data) {
      const employees = data.employees?.edges;
      const sortedEmployees = employees
        ?.map((e) => ({
          name: e?.node?.acfEmployees?.name || '',
          phone: e?.node?.acfEmployees?.phone || '',
          email: e?.node?.acfEmployees?.email || '',
          order: e?.node?.acfEmployees?.order || 999,
        }))
        .sort((a, b) => (a.order > b.order ? 1 : -1));

      sortedEmployees && setEmployees(sortedEmployees);
    }
  }, [data]);

  return (
    <>
      <div className="row m-0">
        <div className="col-12 py-5 mt-5 mb-3 mb-md-5">
          <div className="text-center">
            <Text light large className="mb-3">
              <Text fontSize={36} bold className="mb-3">
                Kontakta oss
              </Text>{' '}
              <Text large bold>
                Adress
              </Text>
              Backegårdsvägen 4 <br />
              45930 Ljungskile <br />
            </Text>
            <Text large bold>
              E-post
            </Text>
            <Text light large>
              <FontAwesomeIcon icon={faEnvelope} />{' '}
              <a href="mailto:info@ljungskileteknik.se">info@ljungskileteknik.se</a>
            </Text>
          </div>
        </div>
      </div>
      {employees && (
        <Employees>
          <div className="container" style={{ maxWidth: 1000 }}>
            <Text fontSize={36} bold className="mb-3">
              Personalen
            </Text>
            <div className="row" style={{ justifyContent: 'center' }}>
              {employees?.map((e) => (
                <div key={e.name} className="col-8 col-md-4 my-4">
                  <ContactCard image={employeePlaceholderImg} name={e.name} phone={e.phone} email={e.email} />
                </div>
              ))}
            </div>
          </div>
        </Employees>
      )}
      <iframe
        title="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2100.1549889627927!2d11.913137551867456!3d58.23890350491521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4645125e5744af75%3A0x82160c234f0c5974!2sLjungskile%20Teknik%20AB!5e0!3m2!1ssv!2sse!4v1614623102253!5m2!1ssv!2sse"
        loading="lazy"
        style={{ border: 'none', minHeight: 400, height: '100%', width: '100%', marginBottom: -7 }}
      ></iframe>
    </>
  );
};

const Employees = styled.div`
  background-color: ${(props) => props.theme.colors.fill_dark};
  text-align: center;
  padding: 60px 0;
`;

export default ContactView;
