import styled from 'styled-components';

const Banner = ({ title, center, backgroundImage }: { title?: string; center?: boolean; backgroundImage?: string }) => {
  return (
    <StyledBanner center={center} backgroundImage={backgroundImage}>
      {title && (
        <div className="container text-center">
          <BannerText>{title}</BannerText>
        </div>
      )}
    </StyledBanner>
  );
};

const StyledBanner = styled.div<{ center?: boolean; backgroundImage?: string }>`
  width: 100%;
  height: 300px;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: url('${(props) => props.backgroundImage}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}
`;

const BannerText = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: -50px;
  color: white;
  position: relative;
  z-index: 10;
`;

export default Banner;
