import styled from 'styled-components';

interface TextProps {
  readonly light?: boolean;
  readonly large?: boolean;
  readonly small?: boolean;
  readonly bold?: boolean;
  readonly color?: string;
  readonly fontSize?: number;
}

export const Text = styled.div<TextProps>`
  color: ${(props) => (props.light ? props.theme.colors.text_light : props.theme.colors.text)};
  font-size: ${(props) => (props.large ? '18px' : props.small ? '14px' : props.theme.fontSize)};

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}

  ${(props) =>
    props.fontSize &&
    `
    font-size: ${props.fontSize}px;
  `}

  ${(props) =>
    props.bold &&
    `
    font-weight: bold;
  `}
`;

export const TextBox = styled(Text)`
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.fill};
`;
