import styled from 'styled-components';

interface IFlex {
  center?: boolean;
}

export const Flex = styled.div<IFlex>`
  display: flex;
  ${(props) => props.center && `align-items: center;`}
`;

export const FlexRight = styled.div`
  margin-left: auto;
`;
