import { DefaultTheme } from 'styled-components';

const appTheme: DefaultTheme = {
  fontSize: '18px',
  borderRadius: '7px',

  colors: {
    primary: '#5ca1ff',
    secondary: '#4d84d0',
    fill: '#f6f6f6',
    fill_light: '#fbfbfb',
    fill_dark: '#e7e7e7',
    text: '#2f2f2f',
    text_light: '#828ea7',
    border: '#dddddd',
    error: '#ff7a7a',
    warning: '#ffa262',
    success: '#8fd97d',
    gray: '#393c41',
  },
};

export { appTheme };
