import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

function FullPageBanner({
  bgImg,
  content,
  contentAlign,
}: {
  bgImg: string;
  content?: JSX.Element;
  contentAlign?: 'CENTER' | 'TOP' | 'BOTTOM';
}) {
  return (
    <PageSection>
      <Banner className="animated fadeIn" backgroundImage={bgImg}></Banner>
      {content && <FullpageContent align={(contentAlign && contentAlign) || 'CENTER'}>{content}</FullpageContent>}
    </PageSection>
  );
}

const PageSection = styled(Div100vh)`
  position: relative;
`;

const Banner = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100%;
  background-image: url('${(props) => props.backgroundImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;

  &:before {
    content: '';
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.09) 2px,
      transparent 2px,
      transparent 4px,
      rgba(0, 0, 0, 0.09) 4px,
      rgba(0, 0, 0, 0.09) 6px,
      transparent 6px,
      transparent
    );
    background-size: 6px 6px;
  }
`;

const FullpageContent = styled.div<{ align?: 'CENTER' | 'TOP' | 'BOTTOM' }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.align === 'CENTER' &&
    `
    top: 50%;
    transform: translate(-50%, -50%);
  `}

  ${(props) =>
    props.align === 'BOTTOM' &&
    `
    bottom: 100px;
  `}  

  ${(props) =>
    props.align === 'TOP' &&
    `
    top: 150px;
  `}
`;

export default FullPageBanner;
