import { Text } from 'ui';
import styled from 'styled-components';
import { useVacanciesQuery, Category } from 'generated/graphql';
import { CallToAction } from 'components';
import { faBolt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

interface IconMapObject {
  elektriker: IconDefinition;
}

const VacancyView = () => {
  // const navigate = useNavigate();
  const { data, loading, error } = useVacanciesQuery();

  const getIcon = (categories: Category[] | null | undefined) => {
    if (!categories || !categories.length) return faBolt;

    const iconMap: IconMapObject = {
      elektriker: faBolt,
      // add more category mappings here
    };

    const firstCategory = categories[0];

    return iconMap[firstCategory.slug as keyof IconMapObject];
  };

  return (
    <>
      <Container className="py-5">
        <div className="container">
          <div className="col-12 col-md-8 mx-auto">
            <FullHeightContainer>
              <div>
                <div className="mb-4">
                  <Text bold fontSize={36}>
                    Vill du bli en av oss?
                  </Text>
                  {!loading && data?.posts?.nodes?.length ? (
                    <Text>
                      Just nu är vi i ett expansivt läge och söker därmed efter fler engagerande och målinriktade
                      medarbetare inom följande område:
                    </Text>
                  ) : null}
                </div>
                {!loading && data?.posts?.nodes?.length ? (
                  data?.posts?.nodes?.map((post) => (
                    <Link key={post?.id} to={'/lediga-tjanster/' + post?.slug} className="mb-3">
                      <CallToAction
                        title={post?.title || 'Okänd titel'}
                        icon={getIcon(post?.categories?.nodes as Category[])}
                      />
                    </Link>
                  ))
                ) : (
                  <Text className="mb-3">
                    {loading ? (
                      <Skeleton height={90} />
                    ) : (
                      <>
                        Vi har ingen specifik tjänst ute just nu men du är välkommen med en spontanansökan.{' '}
                        <a href="mailto:info@ljungskileteknik.se">info@ljungskileteknik.se</a>
                      </>
                    )}
                  </Text>
                )}
              </div>
            </FullHeightContainer>
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.fill};
`;

const FullHeightContainer = styled.div`
  height: calc(100vh - 400px);
  display: flex;
  align-items: center;
`;

export default VacancyView;
