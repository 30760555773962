import { Text, Button, FullPageBanner } from 'ui';
import bgImg from 'media/bg_1600.jpg';
import bgImg2 from 'media/bg2_1600.jpg';

function HomeView() {
  return (
    <>
      <FullPageBanner bgImg={bgImg} />
      <FullPageBanner
        bgImg={bgImg2}
        content={
          <>
            <Text color="white" fontSize={36} bold className="mb-3">
              Fastigheter och lokaler
            </Text>
            <a href={'/fastigheter'}>
              <Button height={40} pill>
                Se fastigheter
              </Button>
            </a>
          </>
        }
        contentAlign="BOTTOM"
      />
    </>
  );
}

export default HomeView;
