import React from 'react';
import styled from 'styled-components';
import { Text } from 'ui';

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const InputText = styled.input`
  padding: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 3px;
  width: 100%;
`;

const InputTextArea = styled.textarea`
  padding: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  background: #fff;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 3px;
  width: 100%;
`;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  error?: string | boolean;
  success?: boolean;
  ref?: React.Ref<HTMLInputElement>;
};

type TextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  label?: string;
  error?: string | boolean;
  success?: boolean;
  ref?: React.Ref<HTMLTextAreaElement>;
};

const InputField: React.FC<InputProps> = React.forwardRef(({ name, label, error, success, ...rest }, ref) => {
  return (
    <FormGroup>
      {label && <Text>{label}</Text>}
      <InputText type="text" ref={ref} name={name} {...rest} />
      {success && (
        <Text color="green">
          <i className={`animated fadeIn fas fa-check-circle`}></i>
        </Text>
      )}
      {error && <Text color="red">{error}</Text>}
    </FormGroup>
  );
});

const TextArea: React.FC<TextAreaProps> = React.forwardRef(({ name, label, error, success, ...rest }, ref) => {
  return (
    <FormGroup>
      {label && <Text>{label}</Text>}
      <InputTextArea rows={5} ref={ref} name={name} {...rest} />
      {success && (
        <Text color="green">
          <i className={`animated fadeIn fas fa-check-circle`}></i>
        </Text>
      )}
      {error && <Text color="red">{error}</Text>}
    </FormGroup>
  );
});

export { InputField, TextArea };
